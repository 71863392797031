<template>
  <div>
    <v-card :loading="loading">
      <v-card-text>
        <h3 class="mb-2">Vistorias</h3>
        <v-card outlined class="py-4 mb-4" v-if="pending.length">
          <h4 class="mx-4 mb-2">Pendentes</h4>
          <inspection-table :inspections="pending" />
        </v-card>

        <v-card outlined class="py-4" v-if="done.length">
          <h4 class="mx-4 mb-2">Finalizadas</h4>
          <inspection-table :inspections="done" />
        </v-card>

        <v-card outlined v-if="!inspections.length && !loading">
          <v-card-text class="text-center">
            <v-icon size="100">mdi-home-search</v-icon>
            <h4 class="mb-2">Nenhuma vistoria encontrada</h4>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import RENTAL from '@/services/rental'
import InspectionTable from '@/components/global/InspectionTable.vue'

export default {
  components: { InspectionTable },
  data: () => ({
    loading: false,
    inspections: [],
  }),
  computed: {
    pending() {
      return this.inspections.filter(i => !i.url)
    },
    done() {
      return this.inspections.filter(i => i.url)
    },
  },
  methods: {
    async getInspections() {
      try {
        const { inspections } = await RENTAL.inspection.getAll()
        this.inspections = inspections
      } catch (error) {
        this.$toast.fire({
          icon: 'error',
          title: 'Ocorreu um erro',
          text: error.message || 'Não foi possível carregar as vistorias',
        })
      }
    },
  },
  mounted() {
    this.getInspections()
  },
}
</script>

<style>
</style>
<template>
  <v-dialog v-model="dialog" max-width="700px" @click:outside="close" :persistent="loading">
    <v-card>
      <v-card-title> Vistoria {{ inspection?.id | idFilter }} </v-card-title>

      <v-card-text v-if="inspection" class="d-flex flex-column" style="gap: 1rem">
        <v-card outlined class="pa-4">
          <h4>
            {{ inspection.Immobile.Address | formatAddress(true) }}
          </h4>
          <div class="d-flex flex-wrap" style="gap: 0.2rem 1.5rem">
            <span v-for="(field, index) in fields" :key="index">
              <strong>{{ field.name }}:</strong> {{ inspection.Immobile[field.key] }}
            </span>
          </div>
          <v-btn
            :href="`https://www.google.com/maps/search/?api=1&query=${$options.filters.formatAddress(
              inspection.Immobile.Address,
            )}`"
            target="_blank"
            color="primary"
            small
            block
            class="mt-2"
          >
            Abrir no Google Maps
          </v-btn>
        </v-card>

        <v-card outlined class="pa-4">
          <h4>Observações:</h4>
          <p class="mb-0">{{ inspection.observations || 'Nenhuma observação' }}</p>
        </v-card>

        <v-card v-if="inspection.url" outlined class="pa-4 d-flex flex-wrap justify-space-between align-center">
          <h4>Vistoria realizada em: {{ inspection.uploadedAt | date }}</h4>
          <v-btn color="primary" :href="inspection.url" target="_blank" :disabled="!inspection.url">Visualizar</v-btn>
        </v-card>
        <v-card v-else-if="inspection.responsibleId == user.id" outlined class="pa-4">
          <h4>Realizar vistoria</h4>
          <v-file-input
            class="py-2"
            v-model="file"
            :rules="[v => !!v || 'Arquivo é obrigatório']"
            accept="application/pdf"
            outlined
            label="Relatório de vistoria"
            prepend-icon="mdi-file"
            dense
            placeholder="Selecione um arquivo (.pdf)"
            hide-details="auto"
          ></v-file-input>
          <v-btn color="primary" block @click="upload" :disabled="!file || loading">Enviar</v-btn>
        </v-card>
      </v-card-text>

      <v-card-actions class="align-center">
        <span> <b>Solicitado por:</b> {{ inspection?.RequiredBy?.name }} </span>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined @click="close" :disabled="loading">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import RENTAL from '@/services/rental'

export default {
  data: () => ({
    valid: true,
    dialog: false,
    loading: false,
    inspection: null,
    file: null,
    fields: [
      {
        name: 'Tipo de imóvel',
        key: 'type',
      },
      {
        name: 'Registro',
        key: 'registry',
      },
      {
        name: 'Registro IPTU',
        key: 'iptuRegistry',
      },
      {
        name: 'Área',
        key: 'area',
      },
      {
        name: 'Bloco/Quadra',
        key: 'block',
      },
      {
        name: 'Lote',
        key: 'lot',
      },
    ],
  }),
  computed: {
    ...mapGetters('auth', ['user']),
  },
  methods: {
    async upload() {
      try {
        this.loading = true
        const { inspection } = await RENTAL.inspection.upload(this.inspection.id, this.file)
        this.$toast.fire({
          icon: 'success',
          title: 'Arquivo enviado com sucesso',
        })
        this.inspection = Object.assign(this.inspection, inspection)
      } catch (e) {
        this.$toast.fire({
          icon: 'error',
          title: 'Erro ao enviar arquivo',
        })
      } finally {
        this.loading = false
      }
    },
    open(inspection) {
      this.file = null
      this.inspection = inspection
      this.dialog = true
    },
    close() {
      if (this.loading) return
      this.dialog = false
      this.loading = false
    },
  },
  props: {},

  mounted() {
    this.$parent.$on('inspection-info', this.open)
  },
}
</script>

<style></style>

<template>
  <div>
    <v-data-table :items="inspections" :headers="headers" :sort-by="sortBy" :sort-desc="sortDesc">
      <template v-slot:item.createdAt="{ item }">
        <p class="mb-0"><b>Solicitado em:</b> {{ item.createdAt | date }}</p>
        <p class="mb-0" v-if="item.url"><b>Realizado em</b>: {{ item.uploadedAt | date }}</p>
      </template>
      <template v-slot:item.Immobile.Address="{ item }">
        {{ item.Immobile.Address | formatAddress }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip v-if="item.uploadedAt" color="success" small label>Realizada</v-chip>
        <v-chip v-else color="error" small label>Pendente</v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn v-if="item.url" :href="item.url" icon target="_blank" color="primary" small class="mr-2">
          <v-icon small>mdi-file-download-outline</v-icon>
        </v-btn>
        <v-btn @click="viewInspection(item)" icon color="primary" small>
          <v-icon small>mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <inspection-info />
  </div>
</template>

<script>
import InspectionInfo from '../modals/InspectionInfo.vue'
export default {
  components: { InspectionInfo },
  methods: {
    viewInspection(inspection) {
      this.$emit('inspection-info', inspection)
    },
  },  
  computed: {
    headers() {
      const headers = [
        { text: 'Data', value: 'createdAt' },
        { text: 'Imóvel', value: 'Immobile.Address' },
        { text: 'Responsavel', value: 'Responsible.name' },
        { text: 'Status', value: 'status' },
      ]
      if (!this.hideActions) headers.push({ text: 'Ações', value: 'actions', sortable: false })
      return headers
    },
  },

  props: {
    hideActions: {
      type: Boolean,
      default: false,
    },
    inspections: {
      type: Array,
      required: true,
    },
    sortBy: {
      type: String,
      default: 'createdAt',
    },
    sortDesc: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style>
</style>